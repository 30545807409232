<template>
  <XModal
    :is-open="show"
    :width="544"
    :actions-classes="[$style.actions]"
    data-testid="blockedFunctionalityModal"
    close-button
    @close="handleCloseModal"
  >
    <div :class="$style.content">
      <div :class="$style.planUpgrade">
        <div :class="$style.titleContainer">
          <XIcon
            size="2xl"
            icon="rocket"
          />
          <h2
            :class="$style.title"
            data-testid="blockedFunctionalityTitle"
          >
            {{ title }}
          </h2>
        </div>
        <Component :is="blockedFunctionality" />
      </div>
    </div>
    <template #actions>
      <div :class="$style.actionButtons">
        <XButton
          design="outlined"
          :to="moreInfoLink"
          target="_blank"
          data-testid="moreInfoButton"
          @click="handleMoreInfoClick"
        >
          {{ $t('general.feature.moreInfo') }}
        </XButton>
        <XButton
          data-testid="changePlanButton"
          @click="openPlansLink"
        >
          {{ buttonText }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { camelCase } from 'lodash';
import { useAccountInfo, useLandingPages, useGA, useToggle, useBreakpoints } from '@base/hooks';
import { FEATURES } from '@base/constants';
import { FUNCTIONALITIES_CONSTANTS } from '@account/constants';

import Customization from '@account/components/subscription/modal/premium/CustomizationFunctionality.vue';
import Integration from '@account/components/subscription/modal/premium/IntegrationFunctionality.vue';
import BatchDownload from '@account/components/subscription/modal/premium/BatchDownloadFunctionality.vue';
import CustomSender from '@account/components/subscription/modal/premium/CustomSenderFunctionality.vue';
import AdditionalAuths from '@account/components/subscription/modal/premium/AdditionalAuthsFunctionality.vue';
import AccountAudit from '@account/components/subscription/modal/premium/AccountAuditFunctionality.vue';
import Security from '@account/components/subscription/modal/premium/SecurityFunctionality.vue';
import { useI18n } from '@/locales';

export default defineComponent({
  name: 'ModalBlockedFunctionality',
  components: {
    Customization,
    Integration,
    BatchDownload,
    CustomSender,
    AdditionalAuths,
    AccountAudit,
    Security,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    plansLink: {
      type: String,
      default: '',
    },
    functionality: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const landingPages = useLandingPages();
    const { isAccountPlan } = useAccountInfo();
    const { breakpoints } = useBreakpoints();
    const isMobile = computed(() => breakpoints.value.extraSmall);
    // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
    // TODO: remove isToggleMidasClientIntegrationEnabled
    const { isEnabled: isToggleMidasClientIntegrationEnabled } = useToggle(
      'TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED'
    );

    const { BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS, BLOCKED_FUNCTIONALITY_MODAL_LINKS } =
      FUNCTIONALITIES_CONSTANTS;

    const integrationTitle = computed(() =>
      !isAccountPlan('avancado')
        ? t('subscription.blockedFunctionality.integration.title')
        : t('subscription.blockedFunctionality.integration.titleAdvanced')
    );

    const defaultTitle = t('subscription.blockedFunctionality.title');
    const additionalAuthTitle = computed(() =>
      !isMobile.value
        ? t('subscription.blockedFunctionality.additionalAuths.title')
        : t('subscription.blockedFunctionality.additionalAuths.titleMobile')
    );
    const titlesByFunctionality = computed(() => ({
      [FEATURES.SAML_LOGIN]: t('subscription.blockedFunctionality.security.title'),
      [FEATURES.INTEGRATION_FEATURES]: integrationTitle.value,
      [FEATURES.CUSTOMIZATION_OF_LOGOS_AND_COLORS]: t(
        'subscription.blockedFunctionality.customization.title'
      ),
      [FEATURES.AUDIT]: t('subscription.blockedFunctionality.accountAudit.title'),
      [FEATURES.ADDITIONAL_AUTH]: additionalAuthTitle.value,
    }));

    const blockedFunctionality = computed(() => {
      const blockedFunctionalities = {
        [FEATURES.ADDITIONAL_AUTH]: AdditionalAuths,
        [FEATURES.AUDIT]: AccountAudit,
        [FEATURES.SAML_LOGIN]: Security,
        [FEATURES.CUSTOM_SENDER]: CustomSender,
        [FEATURES.BATCH_DOWNLOAD]: BatchDownload,
        [FEATURES.INTEGRATION_FEATURES]: Integration,
        [FEATURES.CUSTOMIZATION_OF_LOGOS_AND_COLORS]: Customization,
      };

      return blockedFunctionalities[props.functionality];
    });

    const buttonText = computed(() => {
      const advancedPlanText = isAccountPlan('avancado')
        ? t('subscription.blockedFunctionality.integration.buttonAdvanced')
        : t('subscription.blockedFunctionality.button');

      const buttonTexts = {
        default: t('subscription.blockedFunctionality.button'),
        [FEATURES.INTEGRATION_FEATURES]: advancedPlanText,
      };

      return buttonTexts[props.functionality] || buttonTexts.default;
    });

    const moreInfoLink = computed(() => {
      const functionalityMoreInfoLinks = {
        [FEATURES.INTEGRATION_FEATURES]:
          BLOCKED_FUNCTIONALITY_MODAL_LINKS.integrationFeatures?.moreInfo,
        [FEATURES.SAML_LOGIN]: BLOCKED_FUNCTIONALITY_MODAL_LINKS.samlLogin?.moreInfo,
        [FEATURES.CUSTOMIZATION_OF_LOGOS_AND_COLORS]:
          BLOCKED_FUNCTIONALITY_MODAL_LINKS.customizationOfLogosAndColors?.moreInfo,
        [FEATURES.AUDIT]: BLOCKED_FUNCTIONALITY_MODAL_LINKS.audit?.moreInfo,
        [FEATURES.ADDITIONAL_AUTH]: BLOCKED_FUNCTIONALITY_MODAL_LINKS.additionalAuth?.moreInfo,
      };

      return (
        functionalityMoreInfoLinks[props.functionality] ||
        BLOCKED_FUNCTIONALITY_MODAL_LINKS.default?.moreInfo
      );
    });

    const midasPlansLink = computed(() => {
      if (props.functionality === FEATURES.INTEGRATION_FEATURES) {
        return !isAccountPlan('avancado')
          ? landingPages.value.ADVANCED_PLAN
          : landingPages.value.UPGRADE_API_INTEGRATIONS;
      }

      return props.plansLink;
    });

    const openPlansLink = () => {
      useGA(
        'event',
        BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS[camelCase(props.functionality)]?.upgrade?.action,
        BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS.category
      );

      // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
      // TODO: redirect to props.plansLink when midas is default for all accounts
      return isAccountPlan('trial') || isToggleMidasClientIntegrationEnabled.value
        ? window.open(midasPlansLink.value, '_self')
        : window.open(landingPages.value.UPGRADE_PLAN, '_blank');
    };

    const title = computed(() => titlesByFunctionality.value[props.functionality] || defaultTitle);

    const handleMoreInfoClick = () => {
      useGA(
        'event',
        BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS[camelCase(props.functionality)]?.moreInfo?.action,
        BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS.category
      );
    };

    const handleCloseModal = () => {
      useGA(
        'event',
        BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS[camelCase(props.functionality)]?.close?.action,
        BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS.category
      );
      emit('close');
    };

    return {
      buttonText,
      title,
      FEATURES,
      moreInfoLink,
      openPlansLink,
      blockedFunctionality,
      handleMoreInfoClick,
      handleCloseModal,
    };
  },
});
</script>

<style lang="scss" module>
.actions {
  .actionButtons {
    display: flex;
    gap: var(--space-small-xx);
  }
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .planUpgrade {
    .titleContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: var(--space-regular);
      padding: var(--space-medium);
      color: var(--color-brand-primary-600);
      background: var(--color-neutral-50);
      border-radius: var(--border-radius-medium);

      .title {
        margin: 0 0 0 var(--space-small-x);
        font-weight: var(--font-weight-semibold);
      }
    }
  }
}

@include breakpoint('medium') {
  .content {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}
</style>
